#root{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(20deg,  #51D1E2, #ededed, #fff, #ededed, #51D1E2 );
    background-size: 125% 125%;
    animation: gradient 10s ease infinite;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.sidebar{
    background:black;
}