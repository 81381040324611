.low {
    color: red;
  }
 
.normal{
    color: green;
   }
 .mild{
    color: rgb(204, 204, 42);
 }
.high{
    color: red;
}
.very-high{
    color: red;
}
