.custom-calendar {
    position: absolute;
    top: 30px;
    margin-left:10px;
    margin-right:20px;
    padding-left: 10px;
    /* Aggiungi altre regole di stile necessarie */
   
  }
  .custom-button-grid{
    background-color: #3888e9;
    color: #fff;
    border: none;
    padding: 4%;
    font-size: 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left:10px;
    margin-top: 3px;
    align-self: center;
    max-width: 90%;

  }

  @media  (max-width: 600px) {
    .custom-calendar {
     width: 50%;
   }
  }
  /* Aggiungi altre regole di stile per il componente */
  .arrow-icon {
    transition: color 0.2s;
    margin-right:20px;
    margin-left:20px;
  }
  
  .arrow-icon.clicked {
    transform: scale(1.2);
  }
  
  .arrow-icon:hover {
    color: #ff0000;
  }
  .custom-button {
  
    background-color: #3888e9;
    color: #fff;
    border: none;
    padding: 0.5% 1%;
    font-size: 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left:10px;
    height: 100%;
    align-self: center;
    min-width: 150px;
  }
  @media  (min-width: 600px) {
    .custom-button-grid {    
     padding: 14px 10px;
   }

  }
  @media  (max-width: 600px) {
    .custom-button-grid {    
     padding: 10px 10px;
   }
   
  }

  .custom-button:hover {
    background-color: #dbe3ea;
  }
  .custom-button.selected {
    background-color: #05bad7;
    /* Aggiungi altri stili per il bottone selezionato */
  }
  .text{
    font-size: medium;
  }
 
